import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { OrderStackParamList } from "../../../order/order_param_list";
import Page from "../../../../templates/Page";
import { OrderContextType, useCartContext } from "../../../../../system/CartProvider";
import { PageSubtitle, PageTitle } from "../../../../atoms/TextStyles";
import ValeLaunchButton from "../../../../molecules/ValeLaunchButton";
import { useCallback, useMemo, useState } from "react";
import { useValeContext } from "../../../../../system/ValeSystem";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReviewOrderItem from "./ReviewOrderItem";
import { useToPaymentPage } from "../../../checkout/checkout_process";
import { findMenuItem, getLabelUri } from "../../../../utils/orderItemUtils";
import { CartItem } from "../../../../../system/CartItem";
import { FOOT_PADDING } from "../../../../molecules/footer";
import { useValeStackNavigation } from "../../../../../nav/nav_utils";
import OrderLabelCustomizationModal from "./OrderLabelCustomizationModal";
import { useNoSessionContext } from "../../../../../system/NoSessionProvider";


const PREVIEW_WIDTH = 348 //window.innerWidth;
const PREVIEW_HEIGHT = 468 //window.innerHeight;


const PreviewLabel: React.FC<{item: CartItem, cart: OrderContextType | null, userId: string}> = (props) => {

  
  const labelIamgeSrc = useMemo( () => {
    if ( !props.item ) {
      return undefined;
    }
    return getLabelUri(props.item.customerName!, props.item.name, props.item.labelTemplateNumber!, props.item?.labelImageId)},
  [props.item.customerName!, props.item.name, props.item.labelTemplateNumber!, props.item?.labelImageId])

  return <div>

    <ReviewOrderItem width={PREVIEW_WIDTH} height={PREVIEW_HEIGHT} key={props.item.clientItemId} tempItemId={props.item.clientItemId!} labelIamgeSrc={labelIamgeSrc} itemName={props.item.name}/>
    </div>
}

const ActiveDot = () => <span style={{marginLeft: "4px", marginRight: "4px"}}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="9" cy="9" r="8" transform="rotate(-180 9 9)" fill="white" stroke="#2C2C2C" stroke-width="2"/>
</svg></span>

const InActiveDot = () => <span style={{marginLeft: "4px", marginRight: "4px"}}><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="7" cy="7" r="6" transform="rotate(-180 7 7)" fill="#E30A8F" stroke="#2C2C2C" stroke-width="2"/>
</svg></span>


const CarouselDots: React.FC<{count?: number, activeIndex: number}> = ({count, activeIndex: active}) => {
  if ( !count || count === 1) return null;
  const array = Array.from({length: count}, (_, i) => i)
  const dots = array.map( (index) => { return index === active ? <ActiveDot key={index}/> : <InActiveDot key={index}/>})
  return <div style={{width: "100%", marginTop: "48px", display: "flex", justifyContent: "center", alignItems:"center"}}>
    {dots}
    </div>
}

const ReviewOrderPage: React.FC<
  NativeStackScreenProps<OrderStackParamList, "review">
> = (props) => {

  //const [labels, setLabels] = useState<string[] | undefined>()
  const cart = useCartContext()
  const vale = useValeContext();
  const noSessionInfo = useNoSessionContext();
  const navigation = useValeStackNavigation();
  const userId = useMemo(() => vale?.firebaseAuth?.currentUser?.uid, [vale?.firebaseAuth?.currentUser?.uid])
  const toPaymentPage = useToPaymentPage();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCustomLabelOpen, setIsCustomLabelOpen] = useState(false);

  const onGoBack = useCallback(() => {
    if( navigation.canGoBack() ) {
      navigation.goBack()
    } else {
      navigation.push("order", {screen :"selection"})
    }
    }, [navigation])

  const previews = useMemo(() => {
    return cart?.items.filter( item => item?.clientItemId).map((item) => {
      if ( !(item?.clientItemId) ) return null;
      const menuItem = findMenuItem(noSessionInfo?.menuItems, item.itemTypeId)
      if (!menuItem?.hasValeLabel) {
        return null;
      }

      return <PreviewLabel cart={cart} key={item.clientItemId} item={item} userId={vale?.firebaseAuth?.currentUser?.uid!}/>
    }).filter( (item) => item !== null)
  }, [cart?.items, vale?.firebaseAuth?.currentUser?.uid, noSessionInfo?.menuItems])

  
  const onLabelUpdated = useCallback((imageId?: string) => {
    cart?.updateItem( {clientItemId:  cart.items[activeIndex].clientItemId, labelImageId: imageId})
  },[cart, activeIndex]) 

    return <Page>
      
      <div style={{height: "100%", paddingBottom: FOOT_PADDING}}>
        <PageTitle style={{minWidth:"400px"}}>REVIEW ORDER</PageTitle>
        <PageSubtitle>Preview the goods and customize your own label!</PageSubtitle>
        
        <div style={{ position: "relative"}}>
          <div style={{ display:"flex",alignItems:"center", justifyContent: "center", height: "468px", overflow:"hidden", background: '#DECDFF', borderRadius: 7, border: '2px #2C2C2C solid', maxWidth: "343px", width: "100%", alignSelf: "center", margin: "auto", marginTop: "24px", marginBottom: "16px", }}>
            <Carousel showThumbs={false} width={PREVIEW_WIDTH} showArrows={false} showStatus={false} showIndicators={false} onChange={(index) => {setActiveIndex(index)}}>
            {previews}
            </Carousel>

          </div>  
          <div style={{position: "absolute", width: "100%", bottom: -30, display: "flex", justifyContent: "center"}}>
              <ValeLaunchButton onPress={() => {setIsCustomLabelOpen(true)}} backgroundColor="#FFF500"><span style={{color:"black"}}>UPLOAD IMAGE 📸</span></ValeLaunchButton>
            </div>
        </div>
        <CarouselDots count={cart?.items?.length} activeIndex={activeIndex}/>
      </div>

      <div style={{position: "fixed", width: "100%", height: FOOT_PADDING, bottom: 0, backgroundColor: "#7C50FF", display: "flex", justifyContent: "space-around", alignItems: "center"}}>
        <ValeLaunchButton fontStyle={{fontSize: 18, fontWeight: 700}} onPress={onGoBack}  secondary width={124}>BACK</ValeLaunchButton><ValeLaunchButton  fontStyle={{fontSize: 18, fontWeight: 700}}  onPress={toPaymentPage} width={165}>LOOKS GOOD</ValeLaunchButton>
      </div>
      <OrderLabelCustomizationModal onLabelUpdated={onLabelUpdated} modalOpen={isCustomLabelOpen} onClose={()=>setIsCustomLabelOpen(false)}/>
    </Page>
}

export default ReviewOrderPage;